import * as React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  SelectInput,
  SelectArrayInput,
  ReferenceArrayInput,
  BooleanInput,
} from "react-admin";
import { useTimezones } from "../../hooks/organisations/useTimezones";
import { PRODUCTS } from "./organisation-products";
import { ASSESSMENTS_SOURCES } from "./organisation-assessments-source";
import { valueArrayToObject } from "../common/utils";
import { useCurrencies } from "../../hooks/organisations/useCurrencies";

export const OrganisationCreate = (props) => {
  const timezones = useTimezones();
  const currencies = useCurrencies();

  return (
    <Create {...props} redirect="show">
      <SimpleForm>
        <TextInput source="name" />
        <SelectInput
          source="timezone"
          choices={timezones}
          optionText="timezone"
          optionValue="timezone"
          validate={[required()]}
        />
        <ReferenceArrayInput
          label="Assign BDM users"
          source="bdmIds"
          reference="internal-users"
          filter={{ userType: "INTERNAL" }}
          perPage={100}
        >
          <SelectArrayInput source="id" optionText="email" />
        </ReferenceArrayInput>
        <SelectArrayInput
          source="products"
          choices={valueArrayToObject(PRODUCTS)}
          validate={[required()]}
        />
        <SelectInput
          source="assessmentsSource"
          choices={valueArrayToObject(ASSESSMENTS_SOURCES)}
          validate={[required()]}
          defaultValue={"THRIVE"}
        />
        <BooleanInput source="openForPollination" validate={[required()]} />
        <TextInput
          label="Billing Data Email"
          source="billingData.email"
          validate={[required()]}
        />
        <SelectInput
          source="billingData.currency"
          choices={valueArrayToObject(currencies)}
          validate={[required()]}
          defaultValue={"GBP"}
        />
        <TextInput
          label="SSO Microsoft Tenant Id"
          source="ssoData.msEntraTenantId"
        />
      </SimpleForm>
    </Create>
  );
};
