import * as React from "react";
import { useEffect, useState } from "react";
import { STRIPE_CUSTOMER_URL } from "../../Routes";
import {
  Button,
  ChipField,
  Confirm,
  Datagrid,
  DateField,
  EditButton,
  EmailField,
  NumberField,
  SingleFieldList,
  Pagination,
  ReferenceArrayField,
  ReferenceField,
  ReferenceManyField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  useNotify,
  ArrayField,
  BooleanField,
  FunctionField,
  useRecordContext,
  useShowController,
  usePermissions,
  ShowButton,
} from "react-admin";
import AddUserButton from "./add-user-button";
import ApiClient from "../../ApiClient";
import API from "../../Routes";
import DisableUserButton from "../users/disable-user-button";
import { isInternalAdmin, readOnly } from "../../auth-provider";
import SimpleChipField from "../common/utils";
import { MailOutline } from "@mui/icons-material";
import { Stack, Chip } from "@mui/material";

const SendEmail = () => {
  const record = useRecordContext();
  const [open, setOpen] = useState(false);
  const notify = useNotify();

  return (
    <>
      <Button
        label="Re-send Welcome Email"
        startIcon={<MailOutline />}
        onClick={() => setOpen(true)}
      />
      <Confirm
        isOpen={open}
        title="Re-send Welcome Email"
        content={`Are you sure you want to resend welcome email to ${record.firstName} ${record.lastName}? This will force a password reset for this user.`}
        onConfirm={() => {
          ApiClient.fetch(API.USERS.RESEND_WELCOME_EMAIL, {
            method: "POST",
            body: {
              organisationId: record.organisationId,
              userIds: [record.id],
            },
          });
          setOpen(false);
          notify("Email was sent.");
        }}
        onClose={() => setOpen(false)}
      />
    </>
  );
};

const OrganisationShowTitle = () => {
  const record = useRecordContext();
  return <span>Organisation {record ? `"${record.name}"` : ""}</span>;
};

export const OrganisationShow = ({ ...props }) => {
  const { record } = useShowController();
  const permissions = usePermissions();
  const [experiments, setExperiments] = useState([]);
  const [amountTested, setAmountTested] = useState({
    amountOfCandidates: 0,
    amountOfEmployees: 0,
  });

  useEffect(() => {
    if (record?.id) {
      let didCancel = false;

      (async () => {
        const now = new Date();
        const oneYearAgo = new Date(
          now.getFullYear() - 1,
          now.getMonth(),
          now.getDate()
        );

        const fromDate = oneYearAgo.toISOString().split("T")[0];
        const toDate = now.toISOString().split("T")[0];

        const amountsResponse = await ApiClient.fetch(
          API.ORGANISATIONS.METRICS(record.id, fromDate, toDate)
        );
        const experimentsResponse = await ApiClient.fetch(
          API.EXPERIMENTS.BY_ORGANISATION(record.id)
        );

        if (!didCancel) {
          const amountsData = JSON.parse(amountsResponse.body);
          const experimentsData = JSON.parse(experimentsResponse.body);
          setExperiments(experimentsData.experiments);
          setAmountTested({
            amountOfCandidates: amountsData.amountOfCandidates,
            amountOfEmployees: amountsData.amountOfEmployees,
          });
        }
      })();

      return () => {
        didCancel = true;
      };
    }
  }, [record?.id]);

  return (
    <Show {...props} title={<OrganisationShowTitle />}>
      <TabbedShowLayout>
        <Tab label="Summary">
          <TextField source="id" />
          <TextField source="name" />
          <DateField source="created" />
          <TextField source="status" />
          <TextField source="timezone" />
          <ReferenceArrayField
            label="BDM users"
            source="bdmIds"
            reference="internal-users"
            perPage={100}
          >
            <SingleFieldList linkType={false}>
              <ChipField source="firstName" />
            </SingleFieldList>
          </ReferenceArrayField>
          <ArrayField source="products">
            <SingleFieldList linkType={false}>
              <SimpleChipField />
            </SingleFieldList>
          </ArrayField>
          <BooleanField source="openForPollination" />
          <TextField source="source" />
          <TextField source="assessmentsSource" />
          {experiments.length > 0 ? (
            <Stack direction="row" spacing={1} label="Experiments">
              {experiments.map((experiment) => (
                <Chip key={experiment.variant} label={experiment.variant} />
              ))}
            </Stack>
          ) : null}
        </Tab>
        <Tab label="Credits" path="credits">
          <ReferenceManyField
            perPage={25}
            pagination={<Pagination />}
            sort={{ field: "created", order: "DESC" }}
            label={false}
            reference="credit-transactions"
            target="organisationIds"
          >
            <div>
              <Datagrid bulkActionButtons={false}>
                <TextField source="id" />
                <TextField source="txType" textAlign={"center"} />
                <NumberField source="amount" textAlign={"center"} />
                <ReferenceField
                  source="createdBy"
                  reference="users"
                  link={
                    isInternalAdmin(permissions.permissions) ? "show" : false
                  }
                  emptyText={"System"}
                  textAlign={"center"}
                >
                  <TextField source="firstName" />
                </ReferenceField>
                <DateField source="created" textAlign={"center"} />
                <TextField source="origin" textAlign={"center"} />
              </Datagrid>
            </div>
          </ReferenceManyField>
        </Tab>
        <Tab label="Users" path="users">
          <ReferenceManyField
            perPage={25}
            pagination={<Pagination />}
            label={false}
            reference="users"
            target="organisationIds"
          >
            <Datagrid rowClick={false} bulkActionButtons={false}>
              <TextField source="id" />
              <TextField source="firstName" />
              <TextField source="lastName" />
              <EmailField source="email" />
              <TextField source="role" />
              <ShowButton />
              {!readOnly(permissions.permissions) && <EditButton />}
              {!readOnly(permissions.permissions) && <SendEmail />}
              {!readOnly(permissions.permissions) && <DisableUserButton />}
            </Datagrid>
          </ReferenceManyField>
          {!readOnly(permissions.permissions) && <AddUserButton />}
        </Tab>
        <Tab label="Campaigns" path="campaigns">
          <ReferenceManyField
            perPage={25}
            pagination={<Pagination />}
            label={false}
            reference="campaigns"
            target="organisationIds"
          >
            <Datagrid rowClick="show" bulkActionButtons={false}>
              <TextField source="id" />
              <TextField source="name" />
              <ReferenceField
                source="createdBy"
                reference="users"
                link={isInternalAdmin(permissions.permissions) ? "show" : false}
              >
                <TextField source="firstName" />
              </ReferenceField>
              <ReferenceField
                source="occupationId"
                reference="occupations"
                link="show"
              >
                <TextField source="name" />
              </ReferenceField>
              <TextField source="normType" label="Norm" />
              <NumberField
                source="amountOfCompletedCandidates"
                label="Completed Candidates"
              />
              <NumberField
                source="amountOfCandidates"
                label="Total Candidates"
              />
              <DateField source="startDateTime" />
              <DateField source="endDateTime" />
              <DateField source="updated" />
              <TextField source="language" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="Projects" path="projects">
          <ReferenceManyField
            perPage={25}
            pagination={<Pagination />}
            label={false}
            reference="projects"
            target="organisationIds"
          >
            <Datagrid rowClick="show" bulkActionButtons={false}>
              <TextField source="id" />
              <TextField source="name" />
              <ReferenceField
                source="createdBy"
                reference="users"
                link={isInternalAdmin(permissions.permissions) ? "show" : false}
              >
                <TextField source="firstName" />
              </ReferenceField>
              <DateField source="startDateTime" label="Start Date" />
              <NumberField source="amountOfEmployees" label="Total Employees" />
              <NumberField
                source="amountOfCompletedEmployees"
                label="Completed Employees"
              />
              <DateField source="updated" />
              <TextField source="language" />
              <NumberField source="duration" />
              <NumberField source="type" />
              <NumberField source="timezone" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="Shortlists" path="shortlists">
          <ReferenceManyField
            perPage={25}
            pagination={<Pagination />}
            label={false}
            reference="shortlists"
            target="organisationId"
            sort={{ field: "updated", order: "DESC" }}
          >
            <Datagrid rowClick="show" bulkActionButtons={false}>
              <TextField source="id" />
              <TextField source="name" />
              <ReferenceField
                source="organisationId"
                reference="organisations"
                label="Organisation"
                link="show"
              >
                <TextField source="name" />
              </ReferenceField>
              <ReferenceField
                source="createdBy.id"
                label="Created By"
                reference="users"
                link={isInternalAdmin(permissions.permissions) ? "show" : false}
              >
                <TextField source="firstName" />
              </ReferenceField>
              <DateField source="updated" />
              <NumberField source="amountOfCandidates" label="Candidates" />
              <NumberField
                source="amountOfHiredCandidates"
                label="Hired Candidates"
              />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="Billing Data">
          <TextField label="Email" source="billingData.email" />
          <TextField label="Status" source="billingData.billingStatus" />
          <TextField
            label="Remaining free trial credits amount"
            source="billingData.remainingFreeTrialCredits.amount"
            emptyText="N/A"
          />
          <DateField
            label="Remaining free trial credits expiration date"
            source="billingData.remainingFreeTrialCredits.expirationDate"
            emptyText="N/A"
          />
          <FunctionField
            label="Amount of candidates tested"
            render={(record) =>
              record && <span>{amountTested.amountOfCandidates}</span>
            }
          />
          <FunctionField
            label="Amount of Employees tested"
            render={(record) =>
              record && <span>{amountTested.amountOfEmployees}</span>
            }
          />
          <TextField
            label="Yearly Subscription Package"
            source="billingData.yearlySubscriptionPlan"
          />
          <NumberField
            label="Package Credits"
            source="billingData.subscriptionCredits"
          />
          <NumberField
            label="Credits used from Yearly Subscription"
            source="billingData.usedCredits"
          />
          <TextField label="Currency" source="billingData.currency" />
          <FunctionField
            label="Customer"
            source="billingData.stripeId"
            render={(record) =>
              record.billingData?.stripeId && (
                <a
                  href={`${STRIPE_CUSTOMER_URL}${record.billingData.stripeId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View Customer On Stripe
                </a>
              )
            }
          />
        </Tab>
        <Tab label="SSO">
          <TextField
            label="Microsoft Tenant Id"
            source="ssoData.msEntraTenantId"
            emptyText="N/A"
          />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
