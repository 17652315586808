export const STAGE = process.env.REACT_APP_STAGE || "staging";
export const BASE_PATH =
  STAGE === "production"
    ? "https://api.thrivetech.co"
    : STAGE === "dev"
    ? "http://localhost:8080"
    : "https://api.chasana.biz";
export const STRIPE_CUSTOMER_URL =
  STAGE === "production"
    ? "https://dashboard.stripe.com/customers/"
    : "https://dashboard.stripe.com/test/customers/";
export const AVA_ADMIN_URL =
  STAGE === "production"
    ? "https://player-admin.thrivetech.co"
    : "https://player-admin.chasana.biz";

const API = {
  ORGANISATIONS: {
    STATUS: (organisationId) =>
      `${BASE_PATH}/organisations/${organisationId}/status`,
    GET_TIMEZONES: `${BASE_PATH}/organisations/timezones`,
    GET_CURRENCIES: `${BASE_PATH}/organisations/available-currencies`,
    METRICS: (organisationId, from, to) =>
      `${BASE_PATH}/organisations/${organisationId}/metrics?from=${from}&to=${to}`,
  },
  ROLES: `${BASE_PATH}/users/roles`,
  USERS: {
    RESEND_WELCOME_EMAIL: `${BASE_PATH}/users/resend-welcome-email`,
    STATUS: (userId) => `${BASE_PATH}/users/${userId}/status`,
    EXPORT: (userType) => `${BASE_PATH}/users/export?userType=${userType}`,
  },
  OCCUPATIONS: {
    GET_DEPARTMENTS: `${BASE_PATH}/occupations/departments`,
    GET_ONETCODES: `${BASE_PATH}/occupations/onet-codes`,
  },
  ASSESSMENTS: {
    QUESTIONS: {
      CREATE: (assessmentId) =>
        `${BASE_PATH}/assessments/${assessmentId}/questions`,
      UPDATE: (questionsId) =>
        `${BASE_PATH}/assessments/questions/${questionsId}`,
      FIND: (assessmentId, params) =>
        `${BASE_PATH}/assessments/${assessmentId}/questions?sort=${params.sort.field},${params.sort.order}`,
      GET: (questionsId) => `${BASE_PATH}/assessments/questions/${questionsId}`,
      DELETE: (questionsId) =>
        `${BASE_PATH}/assessments/questions/${questionsId}`,
    },
    IMPROVEMENTS: {
      CREATE: (assessmentId) =>
        `${BASE_PATH}/assessments/${assessmentId}/improvements`,
      UPDATE: (improvementsId) =>
        `${BASE_PATH}/assessments/improvements/${improvementsId}`,
      FIND: (assessmentId, params) =>
        `${BASE_PATH}/assessments/${assessmentId}/improvements?sort=${params.sort.field},${params.sort.order}`,
      GET: (improvementsId) =>
        `${BASE_PATH}/assessments/improvements/${improvementsId}`,
      DELETE: (improvementsId) =>
        `${BASE_PATH}/assessments/improvements/${improvementsId}`,
    },
  },
  SKILL_ASSESSMENTS: {
    STATUS: (skillAssessmentId) =>
      `${BASE_PATH}/skill-assessments/${skillAssessmentId}/status`,
  },
  CANDIDATES: {
    EXPORT: `${BASE_PATH}/candidates/export`,
    GET_URL: (candidateId) => `${BASE_PATH}/candidates/${candidateId}/portal`,
  },
  PROJECT_RESULTS: {
    OVERALL: `${BASE_PATH}/results/overall`,
    OVERALL_TEAMS: `${BASE_PATH}/results/overall/teams`,
    OVERALL_RISKS: `${BASE_PATH}/results/overall/risks`,
    OVERALL_INSIGHTS: `${BASE_PATH}/results/overall/insights`,
  },
  POLLINATION_CANDIDATE: {
    DOWNLOAD_CV: (id) =>
      `${BASE_PATH}/candidate-pollination/${id}/cv-signed-url`,
  },
  EXPERIMENTS: {
    BY_ORGANISATION: (organisationId) =>
      `${BASE_PATH}/experiments?organisationId=${organisationId}`,
  },
};

export default API;
